import React from "react";
import "@fortawesome/fontawesome-free/css/fontawesome.css";

import "./style.scss";
import resume from "../../assets/docs/resume.pdf";

function Button({ children, icon }) {
  return (
    <div>
      <a href={resume} download>
        <button className="btn btn-primary">
          <i className={icon}></i>
          {children}
        </button>
      </a>
    </div>
  );
}

export default Button;
