import * as React from "react";
import { graphql } from "gatsby";
import ExperienceSection from "../components/ExperienceSection";
import ProjectsSection from "../components/ProjectsSection";
import About from "../components/Sidebar/About";
import Links from "../components/Sidebar/Links";
import Resume from "../components/Sidebar/Resume";

import "./style.scss";
import Seo from "../components/seo";

const IndexPage = () => {
  const progressRef = React.useRef(null)
  React.useEffect(() => {
    const handleScroll = () =>  {
      let windowScroll = document.body.scrollTop || document.documentElement.scrollTop
      let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrolled = (windowScroll / height) * 100
      progressRef.current.style.width = scrolled + "%"
    }
    document.addEventListener("scroll", handleScroll)

    return () => document.removeEventListener("scroll", handleScroll)
  })
  return (
    <>
      <div ref={progressRef} className="progress-bar"></div>
      <div className="index">
        <div className="main">
          <h5>
            Hi, am <span className="bold">Ridwan</span>
          </h5>
          <h3 className="bold">
            Front End Engineer with 3 years' experience building products with a
            leading telecomms company, an early stage software development
            startup and an upcoming SaaS provider.
          </h3>

          <ExperienceSection />
          <ProjectsSection />
        </div>

        <div className="aside">
          <div className="top">
            <About />
          </div>
          <div className="mid">
            <Resume />
          </div>
          <div className="bottom">
            <Links />
          </div>
        </div>
      </div>
    </>
  );
};

export const Head = () => <Seo />;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        keywords
      }
    }
  }
`;

export default IndexPage;
