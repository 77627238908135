import React from 'react';
import Section from '../Section';
import ExperienceUnit from '../ExperienceUnit';

import natterlogo from '../../assets/images/experience/natterbase_logo.svg';
// import huaweilogo from '../../assets/images/experience/huawei-logo.svg';
import uwlogo from '../../assets/images/experience/upwork-logo.webp';
import maonlogo from '../../assets/images/experience/M.png';

class ExperienceSection extends React.Component {
	render() {
		return (
			<Section title='Experience'>
				<div className='row'>
					<ExperienceUnit
						logo={maonlogo}
						colour='#000'
						title='Maontech'
						link='https://www.maontech.com/'
						timeperiod='2021 - Present'
						subtitle='Web design, web development, backend development, UI/UX design. Customer relation. Optimized web apps for client that looks great on all device.'
					/>
					<ExperienceUnit
						logo={uwlogo}
						colour='#1D4354'
						title='Freelancer'
						link='https://www.upwork.com/freelancers/~01f8499f44eae79338'
						timeperiod='2023 - Present'
						subtitle='Web design, web development, backend development, UI/UX design. Customer relation. Optimized web apps for client that looks great on all device.'
					/>
					{/* <ExperienceUnit
						logo={huaweilogo}
						colour='#FFFFFF'
						title='Huawei'
						link='https://consumer.huawei.com/ng/support/'
						timeperiod='2018-2020'
						subtitle='Created solutions that detects and manage faults on the network.
            Worked with Airtel ISPC to deploy solutions that automatically diagnoses faults and tries to resolve in real time on fault occurrence'
					/> */}
					<ExperienceUnit
						logo={natterlogo}
						colour='#FFF'
						title='Natterbase'
						link='http://natterbase.com/'
						timeperiod='2018'
						subtitle='Worked as a front-end developer, working on a production level web application based on the Angular.js framework. Integrating various APIs and libraries and also payment gateways to monetize the application.'
					/>
					
				</div>
			</Section>
		);
	}
}

export default ExperienceSection;
