import React, { Component } from "react";

import "./style.scss";

class About extends Component {
  render() {
    return (
      <div className="about">
        <div className="image"></div>
        <div className="bio">
          A FrontEnd Developer with a focus on React. I have experience in
          NodeJS, Angular, Automated Systems.
        </div>
      </div>
    );
  }
}

export default About;
