// import myinshora from "../../assets/images/projects/myinshora.png";
// import iptrack from "../../assets/images/projects/iptrack.png";
// import easyBank from "../../assets/images/projects/easybank.png";
// import sunnySide from "../../assets/images/projects/sunnyside.png";
// import room from "../../assets/images/projects/room.png";
// import timeTracker from "../../assets/images/projects/time-tracker.png";
// import sneakers from "../../assets/images/projects/sneakers.png";
import wyr from "../../assets/images/projects/wyr.png";
// import myreads from "../../assets/images/projects/myreads.png";
import shopaLogo from "../../assets/images/projects/shopa.jpg";
// import rcvrLogo from "../../assets/images/projects/rcvr.png";
// import loop from "../../assets/images/projects/loop.png";
// import price from "../../assets/images/projects/price.png";
import yakoyo from "../../assets/images/projects/screenshot.png";
// import teamify from "../../assets/images/projects/teamify.png";
// import tedx from "../../assets/images/projects/tedx.png";
import oda from "../../assets/images/projects/oda.png";

export const projects = [

  {
    key: 17,
    logo: oda,
    colour: "#fff",
    title: "ODA Marketplace",
    link: "https://oda.com.ng/",
    timeperiod: "2021-present",
    subtitle:
      "Oda offers Merchants of all Kinds (distributors, Wholesaler, Retailer e.t.c.) a suite of services including Payments, Marketing, Logistics and customer engagement tools to simplify the process and cost of stores online.",
  },
  
  // {
  //   key: 15,
  //   logo: teamify,
  //   colour: "#fff",
  //   title: "Teamify",
  //   link: "https://teamify-figma.vercel.app/",
  //   timeperiod: "2022",
  //   subtitle: "A Dashboard page for Admin",
  // },
  // {
  //   key: 14,
  //   logo: myinshora,
  //   colour: "#f58634",
  //   title: "MyInshora",
  //   link: "https://myinshora.com/",
  //   timeperiod: "2019",
  //   subtitle: "An insurance brokage solution.",
  // },
  {
    key: 13,
    logo: wyr,
    colour: "#fbbf24",
    title: "wyr.(The Poll)",
    link: "https://would-rather.vercel.app/login",
    timeperiod: "2021",
    subtitle: "Would you rather this or that.",
  },
  // {
  //   key: 12,
  //   logo: myreads,
  //   colour: "green",
  //   title: "My Reads",
  //   link: "https://shelved-nano.vercel.app/",
  //   timeperiod: "2021",
  //   subtitle: "For avid book readers.",
  // },
  // {
  //   key: 11,
  //   logo: iptrack,
  //   colour: "hsl(0, 0%, 17%)",
  //   title: "IP Tracker",
  //   link: "https://iptrack.vercel.app/",
  //   timeperiod: "2022",
  //   subtitle:
  //     "This uses ipify API to get information about a visitors ISP. Users can also search IP/domain address.",
  // },
  // {
  //   key: 10,
  //   logo: easyBank,
  //   colour: "hsl(192, 70%, 51%)",
  //   title: "EasyBank",
  //   link: "https://easy-banking-rho.vercel.app/",
  //   timeperiod: "2022",
  //   subtitle:
  //     "EasyBank is homepage for a digital bamk. It features different section showcasing the product with subtle animations.",
  // },
  // {
  //   key: 9,
  //   logo: sunnySide,
  //   colour: "hsl(51, 100%, 49%)",
  //   title: "Sunnyside Creatives",
  //   link: "https://creative-agency-topaz.vercel.app/",
  //   timeperiod: "2022",
  //   subtitle:
  //     "A creative agency homepage. Main theme of the UI is fun images and flat griddy homepage.",
  // },
  // {
  //   key: 8,
  //   logo: room,
  //   colour: "#000",
  //   title: "Room",
  //   link: "http://cosy-steel.vercel.app/",
  //   timeperiod: "2021",
  //   subtitle:
  //     "A fictional furniture ecommerce homepage, a grid system showcase each section as card with a flat feel.",
  // },
  // {
  //   key: 7,
  //   logo: timeTracker,
  //   colour: "hsl(226, 43%, 10%)",
  //   title: "Time Tracking Dashboard",
  //   link: "https://time-tracking-ui.vercel.app/",
  //   timeperiod: "2021",
  //   subtitle:
  //     "Stat cards are the main focus of a Time Tracking UI. Curved corners gives a modern feel to the page.",
  // },
  // {
  //   key: 6,
  //   logo: sneakers,
  //   colour: "hsl(26, 100%, 55%)",
  //   title: "Checkout Sneakers",
  //   link: "https://sneakers-ecommerce-mini.vercel.app/",
  //   timeperiod: "2021",
  //   subtitle:
  //     "Components used in this challenge are standard ones a user will expect on a e-commerce product showcase/checkout page.",
  // },
  {
    key: 5,
    logo: yakoyo,
    colour: "#eee",
    title: "Yakoyo",
    link: "https://buka-bytes.vercel.app/",
    timeperiod: "2021",
    subtitle: "A food ordering app",
  },
  // {
  //   key: 3,
  //   logo: price,
  //   colour: "#eee",
  //   title: "Interactive Pricing Tool",
  //   link: "http://fem-interactive-pricing-component-main-git-master.abu-hasib.vercel.app/",
  //   timeperiod: "2021",
  //   subtitle: "An interactive pricing component.",
  // },
  {
    key: 2,
    logo: shopaLogo,
    colour: "#eee",
    title: "Shopa",
    link: "https://nervous-goldstine-f952c5.netlify.app/",
    timeperiod: "2020",
    subtitle:
      "Shopa is front end piece built with Gatsby. It features a landing page that strike the attention of users on their first visit to the site.",
  },
  // {
  //   key: 1,
  //   logo: rcvrLogo,
  //   colour: "#ffc107",
  //   title: "RCVR",
  //   link: "https://distracted-turing-cb6489121.netlify.app/",
  //   timeperiod: "2020",
  //   subtitle: "A car recovery software Built using Angular/Firebase.",
  // },
  // {
  //   key: 0,
  //   logo: tedx,
  //   colour: "#000",
  //   title: "TedXMaitaima",
  //   link: "https://www.tedxmaitama.org/",
  //   timeperiod: "2022",
  //   subtitle:
  //     "A TED-licensed community envisioned to be the leading Pan-African ideas platform showcasing, propagating and supporting African ideas, inventions and innovations for the benefit of humanity.",
  // },
];
