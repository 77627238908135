import * as React from "react";
import Button from "../../Button";
// import "font-awesome/css/font-awesome.min.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";

import "./style.scss";

function Resume() {
  return (
    <div className="resume">
      <Button icon={"fa fa-cloud-download"}>Download Resume</Button>
    </div>
  );
}

export default Resume;
