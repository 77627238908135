import React, { Component } from "react";

import "./style.scss";
import "font-awesome/css/font-awesome.min.css";

class Links extends Component {
  render() {
    return (
      <div className="links">
        <ul className="icons-list">
          <li className="icon">
            <a
              href="https://www.github.com/abu-hasib"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-github"></i>
            </a>
          </li>
          <li className="icon">
            <a
              href="https://www.linkedin.com/in/ridwan-abiola-06002b114"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </li>
          <li className="icon">
            <a
              href="mailto:rabiola85@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-envelope"></i>
            </a>
          </li>
        </ul>
        <div className="small">
          Built on a 💻 using{" "}
          <a href="https://www.gatsbyjs.org/" target="_blank" rel="noreferrer">
            GatsbyJS
          </a>
        </div>
      </div>
    );
  }
}

export default Links;
