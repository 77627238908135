import React, { Component } from "react";

import "./style.scss";

class ProjectUnit extends Component {
  render() {
    return (
      <div className="project-unit col-xs-12 col-sm-6 col-md-4">
        <a href={this.props.link} target="_blank" rel="noreferrer">
          <div
            className="image"
            style={{
              backgroundImage: `url(${this.props.logo})`,
              backgroundColor: this.props.colour,
            }}
          ></div>
        </a>
        <a
          href={this.props.link}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none", color: "#000" }}
        >
          <div className="title bold">{this.props.title}</div>
        </a>
        <div className="time-period">{this.props.timeperiod}</div>
        <div className="subtitle">{this.props.subtitle}</div>
      </div>
    );
  }
}
export default ProjectUnit;
