import React from "react";
import Section from "../Section";
import ProjectUnit from "../ProjectsUnit";

import { projects } from "./data";

class ProjectsSection extends React.Component {
  render() {
    return (
      <Section title="Projects">
        <div className="row">
          {projects.map(({ key, ...props }) => (
            <ProjectUnit key={key} {...props} />
          ))}
        </div>
      </Section>
    );
  }
}

export default ProjectsSection;
